import React, { useEffect, useState } from "react";
import {  useNavigate } from "react-router-dom";
import {
  CommonAnalyticAdd,
  decodeAccessToken,
  fetchUserProfile,
  getLocalStorageItem,
  handleBuyCourse,
  setLocalStorageItem,
} from "../../Utils";
import { Spin } from "antd";
import { FacebookVerify } from "../../ApiServices/Apis/Auth/AuthFun";

const FacebookAuthPage = () => {
  const rawQueryString = window.location.search;
  const params = new URLSearchParams(rawQueryString);
  const rawValue = params.get("code");

  const encodedValue = encodeURIComponent(rawValue);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    setLoading(true);
    FacebookVerify(`?code=${encodedValue}`)
      .then((res) => {
        fetchUserProfile(res.token)
          .then((resp) => {
            if (resp.data.status) {
              setLocalStorageItem("accessToken", res.token);
              setLocalStorageItem("IsLoggesIn", true);
              setLocalStorageItem("userDetail",resp.data.data)
              const decodeToken = decodeAccessToken(res.token);
              CommonAnalyticAdd({
                user_id: decodeToken.userId,
                user_name: `${resp.data.data.firstName} ${resp.data.data.lastName}`,
                channel: "email",
                event_name: decodeToken.eventName,
              });
              if (
                !resp.data?.data?.mobile ||
                !resp.data?.data?.state ||
                !resp.data?.data?.city
              ) {
                return window.location.reload(), navigate("/profile");
              }
              if (
                getLocalStorageItem("courseId") &&
                getLocalStorageItem("payinInstallment")
              ) {
                const payload = {
                  courseId: getLocalStorageItem("courseId"),
                  payInInstallments: getLocalStorageItem("payinInstallment"),
                };
                handleBuyCourse(res.token, payload)
                  .then((res) => window.open(res.data.redirectInfo, "_blank"))
                  .catch((err) => console.log(err));
              } else {
                navigate("/dashboard");
              }
            }
          })
          .catch((err) => console.log(err));
      })
      .catch((err) => console.log(err))
      .finally(() => setLoading(false));
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <Spin spinning={loading}>
        <div className="flex justify-center items-center h-screen">
          <p className="text-lg font-poppins font-bold">Loading......</p>
        </div>
      </Spin>
    </>
  );
};

export default FacebookAuthPage;
