import { AutoComplete, Modal } from "antd";
import React from "react";
import { FaSearch } from "react-icons/fa";

const SearchModal = ({searchTerm,setSearchTerm,options,open,setOpen}) => {
  return (
    <Modal onCancel={()=>setOpen(false)}  open={open} footer={null}>
        <div className="mt-6">
                <AutoComplete
                  suffixIcon={<FaSearch />}
                  options={options}
                  value={searchTerm}
                  onSearch={(val) => setSearchTerm(val)}
                  rootClassName="rounded-md"
                  style={{ borderRadius: "10px !important" }}
                  placeholder="What do you want to learn..... (type atleast 5 letters)"
                  className="border-none rounded-md searchbar hover:border-none w-full focus:border-none h-10 font-poppins"
                />
              </div>
    </Modal>
  );
};

export default SearchModal;
