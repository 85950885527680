import React, { useEffect, useState } from "react";
import { AutoComplete, Badge, Layout, Menu } from "antd";
import { Images } from "../ImageController/Index";

import { useNavigate } from "react-router-dom";
import { MenuItems } from "./MenuItems";
import { getUserProfile } from "../ApiServices/Commonfun";
import { useDispatch, useSelector } from "react-redux";
import { setUserDetail } from "../Store/Slices";
import { GrCart } from "react-icons/gr";
import { NavLink } from "react-router-dom";
import { MdOutlineLogout } from "react-icons/md";
import ProfileSidebar from "./ProfileSidebar";
import { LazyLoadImage } from "react-lazy-load-image-component";
import {
  CHAT_SOCKET_ENDPOINT,
  UPLOAD_ENDPOINT,
} from "../ApiServices/Axios/Index";
import { searchApi } from "../ApiServices/Apis/Course/CourseFun";
import { FaSearch } from "react-icons/fa";
import { getLocalStorageItem, trimString } from "../Utils";
import { IoMdSearch } from "react-icons/io";
import SearchModal from "./SearchModal";
import { GoBell } from "react-icons/go";
import NotificationsDrawer from "./NotificationsDrawer";
import { io } from "socket.io-client";
import { toast } from "react-toastify";
import { ToastNotification } from "../CommonComponents/ToastNotification";

const { Header, Sider, Content } = Layout;
const ProjectLayout = ({ children }) => {
  const [collapsed, setCollapsed] = useState(false);
  const [windowwidth, setwindowwidth] = useState(window.innerWidth);
  const [openProfileDrawer, setOpenProfileDrawer] = useState(false);
  const [options, setoptions] = useState([]);
  const [openSearchModal, setOpenSearchModal] = useState(false);
  const [openNotificationDrawer, setOpenNotificationDrawer] = useState(false);
  const [socket, setSocket] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const selectedTab = useSelector((state) => state.E2C.selectedTab);
  const userDetail = useSelector((state) => state.E2C.userDetail);
  // const navigate = useNavigate();

  useEffect(() => {
    const handleResize = () => {
      setwindowwidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  useEffect(() => {
    if (windowwidth > 1000) {
      setCollapsed(true);
    } else {
      setCollapsed(true);
    }
  }, [windowwidth]);
  useEffect(() => {
    const socketInstance = io(CHAT_SOCKET_ENDPOINT, {
      transports: ["websocket"],
      auth: {
        token: getLocalStorageItem("accessToken"),
      },
    }); // Replace with your server URL
    setSocket(socketInstance);
    return () => {
      socketInstance.disconnect();
    };
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (socket) {
      socket.on("new_announcement", (data) => {
        const d = JSON.parse(data);
        console.log({ d, data }, "GroupMessageNotification");
        toast(<ToastNotification i={d} />);
      });
      socket.on("new_notification", (data) => {
        const d = JSON.parse(data);
        console.log({ d, data }, "GroupMessageNotification");
        d?.user_id === userDetail?.userId && toast(<ToastNotification i={d} />);
      });
    }
    return () => {
      socket?.off("new_announcement"); // Clean up the listener
      socket?.off("new_notification"); // Clean up the listener
    };
    //eslint-disable-next-line
  }, [socket]);
  const getUserData = () => {
    getUserProfile()
      .then((res) => {
        if (res.status) {
          dispatch(setUserDetail(res.data));
        }
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    getUserData();
    //eslint-disable-next-line
  }, []);
  useEffect(() => {
    let delayDebounce;
    if (searchTerm !== "" && searchTerm.length >= 5) {
      delayDebounce = setTimeout(() => {
        searchApi(`?searchKey=${searchTerm}&limit=10`)
          .then((res) => {
            if (res.success) {
              console.log(res);
              setoptions(
                res.data.items.map((i, index) => {
                  return {
                    label: (
                      <div
                        onClick={() => {
                          navigate(`/courseDetail/${i._id}`);
                          setSearchTerm("");
                          setOpenSearchModal(false);
                        }}
                        className="flex   p-[6px] text-[#4F4F4F] text-xs lg:text-sm font-inter mt-4 justify-between items-center flex-wrap"
                      >
                        {/* <div className="w-[10%] hidden xl:block">
                          <LazyLoadImage
                            src={UPLOAD_ENDPOINT + i.thumbnail}
                            alt="thumbnail"
                            className="w-6 h-6 object-cover rounded-full"
                            onError={(err) => {
                              err.target.src = Images.CourseImage;
                              err.target.alt = "thumbnail";
                            }}
                          />
                        </div> */}
                        <div className="w-[75%]">
                          <p>{i?.title}</p>
                          <p>{trimString(i.description, 40)}</p>
                        </div>
                        <div className="w-[15%] hidden md:block">
                          <p>{i.category}</p>
                        </div>
                      </div>
                    ),
                    value: index,
                  };
                })
              );
            }
          })
          .catch((err) => console.log(err));
      }, 400);
    }
    return () => clearTimeout(delayDebounce);
    //eslint-disable-next-line
  }, [searchTerm]);
  return (
    <>
      <Layout hasSider className="min-h-screen sidebar">
        <Sider
          style={{
            position: "fixed",
            marginBottom: "20px",
            overflowY: "scroll",
            height: "100vh",
          }}
          trigger={null}
          collapsible
          className=""
          collapsed={collapsed}
        >
          <div className="h-full relative w-full">
            <div className="demo-logo-vertical flex justify-around items-center my-5">
              {!collapsed ? (
                <>
                  <div
                    onClick={() => setCollapsed(!collapsed)}
                    className="flex cursor-pointer gap-4 justify-start items-center"
                  >
                    <img
                      src={Images.FullLogo}
                      className=" w-[75%] ml-5"
                      alt="Weblogo"
                    />
                  </div>
                </>
              ) : (
                <img
                  onClick={() => setCollapsed(!collapsed)}
                  src={Images.Logo}
                  alt="WeblogoSmall"
                  className="h-8 cursor-pointer w-8"
                />
              )}
            </div>
            <Menu
              theme="dark"
              mode="inline"
              defaultSelectedKeys={["1"]}
              selectedKeys={[selectedTab]}
              items={MenuItems()}
            />
            <Profile collapsed={collapsed} />
          </div>
        </Sider>
        <Layout className="w-full">
          <Header
            className={`bg-white h-20 flex flex-row items-center justify-between ${
              collapsed ? "ml-[45px]" : "ml-[200px]"
            }`}
          >
            <div className="flex justify-end w-full px-4 items-center gap-10">
              <div className="w-full md:block hidden">
                <AutoComplete
                  suffixIcon={<FaSearch />}
                  options={options}
                  value={searchTerm}
                  onSearch={(val) => setSearchTerm(val)}
                  rootClassName="rounded-md"
                  style={{ borderRadius: "10px !important" }}
                  placeholder="What do you want to learn..... (type atleast 5 letters)"
                  className="border-none rounded-md searchbar hover:border-none w-[70%]  lg:w-10/12 2xl:w-1/4  focus:border-none h-10 font-poppins"
                />
              </div>
              <div className="flex flex-row justify-center items-center gap-4">
                <div className="block md:hidden">
                  <p
                    onClick={() => setOpenSearchModal(true)}
                    className="cursor-pointer "
                  >
                    <IoMdSearch size={22} />
                  </p>
                </div>
                <p
                  onClick={() => setOpenNotificationDrawer(true)}
                  className="cursor-pointer mt-2"
                >
                  <Badge dot size="small">
                    <GoBell size={20} />
                  </Badge>
                </p>
                <NavLink to={"/cart"}>
                  <p className="cursor-pointer mt-2">
                    <Badge>
                      <GrCart size={20} />
                    </Badge>
                  </p>
                </NavLink>
                {/* <Dropdown trigger={["click"]} menu={{ items: Profileitems }}> */}
                <LazyLoadImage
                  onClick={() => setOpenProfileDrawer(true)}
                  loading="lazy"
                  src={UPLOAD_ENDPOINT + userDetail?.profilePicture}
                  onError={(e) => {
                    e.target.src = Images.DummyImage;
                    e.target.alt = "dummy";
                  }}
                  alt="userPhoto"
                  className="w-10 bg-gray-200 object-contain cursor-pointer h-10 rounded-full"
                />
                {/* </Dropdown> */}
              </div>
            </div>
          </Header>

          <Content
            className={`bg-white max-h-screen h-full p-5 md:p-8
                 ${collapsed ? "ml-[45px]" : "ml-[200px]"}
            `}
          >
            {children}
          </Content>
        </Layout>
      </Layout>
      {openProfileDrawer && (
        <ProfileSidebar
          open={openProfileDrawer}
          setOpen={setOpenProfileDrawer}
        />
      )}
      {openNotificationDrawer && (
        <NotificationsDrawer
          open={openNotificationDrawer}
          setOpen={setOpenNotificationDrawer}
        />
      )}
      <SearchModal
        options={options}
        searchTerm={searchTerm}
        open={openSearchModal}
        setOpen={setOpenSearchModal}
        setSearchTerm={setSearchTerm}
      />
    </>
  );
};

const Profile = ({ collapsed }) => {
  const navigate = useNavigate();
  const userDetail = useSelector((state) => state.E2C.userDetail);

  return (
    <div className="absolute bottom-10 w-full flex flex-row justify-center items-center text-white">
      <div className="w-[85%] h-full border-t-2 flex flex-row justify-between items-center border-white">
        {!collapsed ? (
          <>
            <div className="hidden md:flex flex-row gap-2 items-center">
              <LazyLoadImage
                loading="lazy"
                src={UPLOAD_ENDPOINT + userDetail?.profilePicture}
                onError={(e) => {
                  e.target.src = Images.DummyImage;
                  e.target.alt = "dummy";
                }}
                alt="userPhoto"
                className="my-2 w-10 bg-gray-200 object-contain h-10 rounded-full"
              />
              <div>
                <p className="text-white capitalize font-bold">
                  {userDetail?.firstName}
                </p>
                <small className=" text-gray-400">Student</small>
              </div>
            </div>
            <div className="flex flex-row justify-center items-center w-10 h-10">
              <MdOutlineLogout
                onClick={() => {
                  localStorage.clear();
                  navigate("/");
                }}
                size={20}
                className="cursor-pointer"
              />
            </div>
          </>
        ) : (
          <div className="flex flex-row justify-center items-center w-10 h-10">
            <MdOutlineLogout
              onClick={() => {
                localStorage.clear();
                navigate("/");
              }}
              size={20}
              className="cursor-pointer"
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default ProjectLayout;
