import { BrowserRouter, useRoutes } from "react-router-dom";
import "./App.css";
import "react-slideshow-image/dist/styles.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { PrivateAuth } from "./Routes/PrivateRoutes";
import { PublicRoutes } from "./Routes/PublicRoutes";
import "react-toastify/dist/ReactToastify.css";
import { getLocalStorageItem, ToastContainerMsg } from "./Utils";
import { useEffect, useState } from "react";

function App() {
  const [Routing, setRouting] = useState([...PublicRoutes(), ...PrivateAuth()]);
  const RouteConfig = () => useRoutes(Routing);

  useEffect(() => {
    if (
      getLocalStorageItem("accessToken") &&
      getLocalStorageItem("persist:root")?.userDetail
    ) {
      const getUserDetail = JSON.parse(
        getLocalStorageItem("persist:root")?.userDetail ?? {}
      );
      if (
        getUserDetail &&
        (!getUserDetail?.mobile ||
          !getUserDetail?.state ||
          !getUserDetail?.city)
      ) {
        let data = [...PrivateAuth()];
        data[0].children = data[0].children?.filter((i) =>
          i.path?.includes("profile")
        );
        setRouting([...PublicRoutes(), ...data]);
      } else {
        setRouting([...PublicRoutes(), ...PrivateAuth()]);
      }
    } else {
      setRouting([...PublicRoutes(), ...PrivateAuth()]);
    }
  }, []);
  return (
    <>
      <BrowserRouter>
        <RouteConfig />
      </BrowserRouter>
      <ToastContainerMsg />
    </>
  );
}

export default App;
