import React, { Suspense, useEffect } from "react";
import { Navigate, Outlet, useLocation, useNavigate } from "react-router-dom";
import ErrorBoundary from "../ErrorBoundry";
import { getLocalStorageItem } from "../Utils";
import ProjectLayout from "../ProjectLayout";
import { Skeleton } from "antd";

function ProtectedAuth() {
  const location = useLocation();
  const navigate = useNavigate();
  const isAuthenticated = () => {
    const user = getLocalStorageItem("accessToken");
    return user;
  };
  useEffect(() => {
    if (
      getLocalStorageItem("accessToken") &&
      getLocalStorageItem("userDetail")
    ) {
      const getUserDetail = getLocalStorageItem("userDetail") ?? {};

      if (
        !getUserDetail?.mobile ||
        !getUserDetail?.state ||
        !getUserDetail?.city
      ) {
        navigate("/profile");
      }
    }
    //eslint-disable-next-line
  }, []);
  return isAuthenticated() ? (
    <>
      <ProjectLayout>
        <ErrorBoundary>
          <Suspense
            fallback={
              <>
                <Skeleton active className="mt-4" />
                <Skeleton active className="mt-4" />
                <Skeleton active className="mt-4" />
                <Skeleton active className="mt-4" />
              </>
            }
          >
            <Outlet />
          </Suspense>
        </ErrorBoundary>
      </ProjectLayout>
    </>
  ) : (
    <>
      <Navigate to={`/${location.search}`} replace />
    </>
  );
}
export default ProtectedAuth;
